import { type HomeDashboardWidgetsEnum } from '../../../constants/home';
import { TrackedEvent, TrackedEventCase } from '../event-types';
import { type GTMCustomEvent } from './gtm-types';

export const buildGTMOrderNumberClick = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.ORDER_NUMBER_CLICK
});

export const buildGTMViewAllOrders = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_ALL_ORDERS
});

export const buildGTMViewProject = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_PROJECT
});

export const buildGTMViewAllProjects = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_ALL_PROJECTS
});

export const buildGTMViewCartItem = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_CART_ITEM
});

export const buildGTMViewCart = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_CART
});

export const buildGTMViewShopByLook = (): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.VIEW_SHOP_BY_LOOK
});

export const buildGTMWidgetsDisplayed = (displayedWidgets: HomeDashboardWidgetsEnum): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD,
	type: TrackedEventCase.WIDGETS_DISPLAYED,
	displayedWidgets
});

export const buildGTMNoWidgetsDisplayed = (displayedWidgets: HomeDashboardWidgetsEnum): GTMCustomEvent => ({
	event: TrackedEvent.HOMEPAGE_DASHBOARD_CONTROL,
	type: TrackedEventCase.NO_WIDGETS_DISPLAYED,
	displayedWidgets
});
